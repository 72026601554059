@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap');
@import 'data-table.scss';
@import 'animations.scss';

html {
  overflow-y: scroll;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  background-color: hsl(220, 13%, 18%);
  text-align: center;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c6c6c6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(83, 83, 83);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(57, 57, 57);
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid rgb(255, 255, 255); */
  -webkit-text-fill-color: rgb(0, 0, 0);
  box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
  -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
  transition: background-color 5000s ease-in-out 0s;
  /* font-size: 1.2rem; */
}

/* Change Autocomplete styles in Firefox*/
input {
  filter: none;
}
