// $text-color: white;
// $primary-color: #5d5c61;
// $border-color: #5cb3b1;
// $border-radius: 5px;

// .rt-thead {
//   background: $primary-color;
//   border-radius: $border-radius;
// }
// .ReactTable .rt-thead .rt-header-pivot:after {
//   border-color: rgba(255, 255, 255, 0);
//   //   border-color: rgba(255, 255, 255, 0);
//   border-left-color: #fff;
// }

// .ReactTable .rt-thead .rt-header-pivot:before {
//   border-color: rgba(102, 102, 102, 0);
//   //   border-color: rgba(102, 102, 102, 0);
//   border-left-color: #f7f7f7;
// }

// .ReactTable .rt-thead.-filters input,
// .ReactTable .rt-thead.-filters select {
//   border: 1px solid $border-color;
//   //   border: 1px solid rgba(0, 0, 0, 0.1);
//   background: #fff;
// }

// .ReactTable .rt-thead .rt-header-pivot {
//   border-right-color: #f7f7f7;
// }

// .ReactTable {
//   background: $primary-color;
//   color: $text-color;
//   border-color: $border-color;
//   border-radius: $border-radius;
// }

// ROW TRANSITIONS

// .rowTransition-enter {
//   opacity: 0;
// }

// .rowTransition-enter-active {
//   opacity: 1;
//   transition: opacity 500ms ease-in;
// }

// .rowTransition-exit {
//   opacity: 1;
// }

.rowTransition-exit-active {
  opacity: 0;
  height: 0px;
  transition: opacity 500ms ease-in;
}
